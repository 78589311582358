@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);
@font-face{font-family:"Nexa";src:url(/static/media/nexa_regular.4ab089d4.woff2) format("woff2"),url(/static/media/nexa_regular.bf5588d4.woff) format("woff"),url(/static/media/nexa_regular.b64bd18e.ttf) format("truetype")}@font-face{font-family:"NexaBold";src:url(/static/media/nexa_bold.9eb7d2c0.woff2) format("woff2")}html,body{margin:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-rendering:optimizeLegibility;height:100%;font-family:"Nexa","NexaBold",Arial}@media only screen and (min-width: 2000px){html,body{font-size:calc(16px + 8*(100vw - 2000px)/900)}}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}.App{text-align:center}.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear;height:40vmin;pointer-events:none}.App-header{background-color:#fff;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}label+.MuiInput-formControl{margin-top:25px}.MuiFormLabel-root{font-weight:700}.vidButton{text-transform:none;color:#fff;width:282px}.vidButton span{font-size:18px}.input-label{padding-top:20px;padding-bottom:20px}/*!* Chrome, Safari, Edge, Opera *!
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

!* Firefox *!
input[type=number] {
    -moz-appearance: textfield;
}*/.mainLogo{margin-left:-135px;padding-bottom:40px}.mainLogo1{margin-left:-135px}@-webkit-keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}h1{position:relative;font-size:smaller;z-index:1;overflow:hidden;text-align:center;color:#c70101;padding-left:20px;padding-right:20px}.color{background-color:#ccc}.MuiPaper-root{width:100%}.break{border-bottom:1px solid gray;padding-top:5px;padding-bottom:15px}.redText{color:#c70101 !important}.MuiTabs-fixed{background-color:transparent}.MuiButton-label{text-transform:none}.video-container{padding-top:20px;position:relative;padding-bottom:56.25%;height:0}.video-container iframe{position:absolute;top:0;left:0;width:100%;height:100%}
*{box-sizing:border-box;margin:0;padding:0}html{height:100%}.container{width:75%;max-width:700px;margin:1.5rem auto;display:flex;flex-direction:column;align-items:center;font-family:"Nunito",sans-serif}@media(max-width: 650px){.container{width:85%}}.container .header{color:#fb3958;font-size:5em;font-weight:700;text-align:center;text-shadow:2px 2px 5px #b1041f}@media(max-width: 650px){.container .header{font-size:3em}}.compcontainer{width:75%;height:13rem;padding:1rem 0}@media(max-width: 650px){.compcontainer{height:10rem}}.compcontainer svg{max-width:100%;max-height:100%;-webkit-animation:bouncy 1300ms linear infinite;animation:bouncy 1300ms linear infinite}.instructions{background:#fefefe;width:80%;height:auto;padding:1rem;border:1px solid #dcdcdc;border-radius:.25rem}@media(max-width: 650px){.instructions{width:100%}}.instructions h2{font-size:1.25em;line-height:1.3;color:#e30528}@media(max-width: 650px){.instructions h2{font-size:1.05em}}.instructions p{font-size:1.15em;line-height:1.5;color:#122125}@media(max-width: 650px){.instructions p{font-size:1em}}.instructions .step{display:flex;flex-direction:row;width:100%;height:1.5rem;margin:.5rem 0}.instructions .step .icon{width:1.25rem;height:1.25rem;align-self:center}@media(max-width: 650px){.instructions .step .icon{width:1rem;height:1rem}}.instructions .step p{display:inline-block;width:80%;line-height:1.5;padding-left:.5rem}@-webkit-keyframes bouncy{0%{transform:translateY(10px) translateX(0) rotate(0)}25%{transform:translateX(-10px) rotate(-10deg)}50%{transform:translateX(0) rotate(0deg)}75%{transform:translateX(10px) rotate(10deg)}100%{transform:translateY(10px) translateX(0) rotate(0)}}@keyframes bouncy{0%{transform:translateY(10px) translateX(0) rotate(0)}25%{transform:translateX(-10px) rotate(-10deg)}50%{transform:translateX(0) rotate(0deg)}75%{transform:translateX(10px) rotate(10deg)}100%{transform:translateY(10px) translateX(0) rotate(0)}}
