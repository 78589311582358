@font-face {
    font-family: "Nexa";
    src: url("Custom/Fonts/nexa_regular.woff2") format("woff2"), /* Super Modern Browsers */ url("Custom/Fonts/nexa_regular.woff") format("woff"),
        /* Pretty Modern Browsers */ url("Custom/Fonts/nexa_regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "NexaBold";
    src: url("Custom/Fonts/nexa_bold.woff2") format("woff2"); /* Super Modern Browsers */
}

html,
body {
    /* https://www.smashingmagazine.com/2016/05/fluid-typography/ */
    /* https://css-tricks.com/snippets/css/fluid-typography/ */
    /* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
    //font-size: calc(12px + (16 - 12) * ((100vw - 350px) / (1366 - 350)));
    /* font-size: calc(0.15em + 1vw); */
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    height: 100%;
    // scroll-behavior: smooth;
    font-family: "Nexa", "NexaBold", Arial;
}
@media only screen and (min-width: 2000px) {
    html,
    body {
        font-size: calc(16px + (24 - 16) * ((100vw - 2000px) / (2900 - 2000)));
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

label + .MuiInput-formControl {
    margin-top: 25px;
}

.MuiFormLabel-root {
    font-weight: 700;
}

/*.MuiInputBase-input {
    padding-left:10px;
}*/

.vidButton {
    text-transform: none;
    //font: normal normal normal 18px/23px NexaRegular ☞;
    color: white;
    width: 282px;
    span {
        font-size: 18px;
    }
}

.input-label {
    padding-top: 20px;
    padding-bottom: 20px;
}

/*!* Chrome, Safari, Edge, Opera *!
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

!* Firefox *!
input[type=number] {
    -moz-appearance: textfield;
}*/

.mainLogo {
    margin-left: -135px;
    padding-bottom: 40px;
}

.mainLogo1 {
    margin-left: -135px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

h1 {
    position: relative;
    font-size: smaller;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: #c70101;
    padding-left: 20px;
    padding-right: 20px;
}
/*h1:before,
h1:after {
    position: absolute;
    top: 35%;
    overflow: hidden;
    width: 50%;
    height: 2px;
    content: "\a0";
    background-color: #c70101;
}
h1:before {
    margin-left: -50%;
    text-align: right;
}*/
.color {
    background-color: #ccc;
}
// .loginSection {
//     margin-left: 200px;
// }
.MuiPaper-root {
    width: 100%;
}
.break {
    border-bottom: 1px solid grey;
    padding-top: 5px;
    padding-bottom: 15px;
}
.redText {
    color: #c70101 !important;
}

.MuiTabs-fixed {
    background-color: transparent;
}

.MuiButton-label {
    text-transform: none;
}

.video-container {
    padding-top: 20px;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
